:root { 
  --alto: #d9d9d9;
  --black: #000000;
  --blue-ribbon: #004cff;
  --delta: #a2a2a2;
  --fuscous-gray: #565656;
  --white: #ffffff;
 
  --font-size-l: 15px;
  --font-size-m: 14px;
  --font-size-s: 12px;
  --font-size-xl: 30px;
  --font-size-xs: 10px;
 
  --font-family-inter: "Inter", Helvetica;
}
.inter-light-black-10px {
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 300;
}

.inter-bold-black-30px {
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.inter-medium-white-10px {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
}
