.loginscreen {
  align-items: center;
  background-color: var(--white);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100vh;
  min-height: 699px;
  min-width: 835px;
  padding: 98px 0px 84px;
  position: relative;
  width: 100%;
}

.logo {
  height: 30px;
  min-width: 177px;
}

@media only screen and (max-width: 834px) {
  .loginscreen {
    width: 100%;
    min-width: 100%;
  }
}

