.tasks {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 100vh;
  min-height: 699px;
  min-width: 835px;
  /* padding: 60px 94px 84px; */
  position: relative;
  width: 100%;
}

.frame-tasks {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: min-content;
  position: relative;
  width: min-content;
}