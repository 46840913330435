.log-in-form {
  align-items: center;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 3px 2px #0084ff20;
  display: flex;
  flex-direction: column;
  gap: 17px;
  height: min-content;
  justify-content: center;
  padding: 32px 50px 41px;
  position: relative;
  width: 333px;
}

.frame-header {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 15px;
  height: 22px;
  justify-content: center;
  padding: 10px 0px;
}

.text-project-name {
  letter-spacing: 0;
  margin-bottom: -16px;
  margin-top: -18px;
  min-width: 89px;
  text-align: center;
}

.frame-para {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: min-content;
  justify-content: center;
  padding: 0px 10px;
}

.para {
  color: var(--delta);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  margin-left: -3px;
  margin-right: -3px;
  margin-top: -1px;
  text-align: center;
  width: 219px;
}

.outline {
  align-items:flex-start;
  align-self: stretch;
  border: 1px solid var(--delta);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 25px;
  justify-content: center;
  padding: 10px;
}

.email {
  background-color: transparent;
  border: 0;
  color: #4e4e4e;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 500;
  height: 12px;
  letter-spacing: 0;
  margin-bottom: -2.5px;
  margin-top: -4.5px;
  min-width: 220px;
  padding: 0;
}

.email::placeholder {
  color: #4e4e4e99;
}

.email-success-blurb {
  /* border: 1px solid var(--blue-ribbon);
  background-color: var(--blue-ribbon);
  color: white; */
  border-radius: 7px;
  padding: 10px;
  -webkit-animation: fadeInDown 500ms ease-in-out; /* Chrome, Safari, Opera */
  animation: fadeInDown 500ms ease-in-out;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-30px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
}

/* Standard syntax */
@keyframes fadeInDown {
  0% {
      opacity: 0;
      transform: translateY(-30px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

