.due-date {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 0px 1px;
  font-family: Inter;
}

.frame-static-1 {
  align-items: center;
  display: flex;
  gap: 4px;
  height: min-content;
  justify-content: center;
  width: min-content;
}

.vector {
  height: 11px;
  min-width: 11px;
}

.due-date-1 {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 45px;
  min-width: 80px;
  font-family: Inter;
  font-weight: 500;
  font-size: small;
}

.date {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 80px;
  font-family: Inter;
  color: #bbb;
  font-size: small;
}

