.info-line {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 12px;
  height: min-content;
  position: relative;
}

.info-line-item {
  height: 20px;
  min-width: 20px;
}

.task-name {
  color: var(--black);
  flex: 1;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 700;
  letter-spacing: 0;
}

