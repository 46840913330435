.project-header-status {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 10px;
  height: min-content;
  justify-content: flex-end;
  padding: 10px 0px;
}

.text-status {
  color: var(--blue-ribbon);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 900;
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 56px;
}

