.notes-frame {
  align-items: flex-start;
  align-self: stretch;
  border: solid var(--alto) 1px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: min-content;
  padding: 16px 16px;
}

.headline-frame {
  align-items: center;
  display: flex;
  gap: 4px;
  height: min-content;
  width: min-content;
}

.file-text-fill {
  height: 15px;
  min-width: 15px;
}

.notes {
  letter-spacing: 0;
  min-width: 29px;
  font-family: Inter;
  font-weight: 500;
  font-size: small;
}

.frame-paragraph {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: min-content;
  padding: 0px 10px;
}

.paragraph {
  flex: 1;
  letter-spacing: 0;
  line-height: 11px;
  margin-top: -1px;
  text-align: justify;
  font-family: Inter;
  /* color: var(--alto); */
  font-size: small;
}

