.project-loading {
  align-items: flex-start;
  /* background-color: var(--alto); */
  border-radius: 8px;
  /* box-shadow: 0px 0px 3px 2px #0084ff20; */
  background: linear-gradient(116deg, #eee 0.00%, #eee 25.00%, white 50.00%, #eee 75.00%, #eee 100.00%);
  background-size: 500% 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: min-content;
  padding: 18px 30px 19px;
  position: relative;
  width: 649px;
  height: 200px;
}
