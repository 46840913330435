.task-summary {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 4px;
  width: min-content;
}

.completed {
  color: var(--fuscous-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 500;
  letter-spacing: 0;
  min-width: 53px;
}

.completed-date {
  color: #21cc7e;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 500;
  letter-spacing: 0;
  min-width: 58px;
  width: 80px;
}

