.lottie {
  height: 50px;
  min-width: 268.82px;
}

@media only screen and (max-width: 834px) {
  .lottie {
    width: 100%;
    min-width: 50px;
  }
}

