.projects {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* height: 100vh; */
  min-height: 699px;
  min-width: 835px;
  padding: 60px 0px 84px;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 835px) {
  .projects {
    width: 95%;
    min-width: 395px;
  }
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
