.header {
  display: flex;
  height: 61px;
  min-width: 649px;
  margin-bottom: 15px;
}

.frame-9 {
  align-items: center;
  display: flex;
  gap: 21px;
  width: 649.45px;
}

.shape-rectangle {
  align-self: stretch;
  background-color: var(--alto);
  border-radius: 32px;
  height: 59px;
  min-width: 5.14px;
}

.frame-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: min-content;
  justify-content: center;
  position: relative;
  width: min-content;
}

@media only screen and (max-width: 834px) {
  .header {
    width: 95%;
    min-width: 95%;
  }

  .frame-9 {
    width: 95%;
    min-width: 95%;
  }
}

.frame-project-name {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: min-content;
  justify-content: center;
  position: relative;
  width: min-content;
}

.text-company-name {
  letter-spacing: 0;
  margin-top: -1px;
  width: 100%;
}

.text-loading {
  align-items: flex-start;
  /* background-color: var(--alto); */
  border-radius: 8px;
  /* box-shadow: 0px 0px 3px 2px #0084ff20; */
  background: linear-gradient(116deg, #eee 0.00%, #eee 25.00%, white 50.00%, #eee 75.00%, #eee 100.00%);
  background-size: 500% 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 200px;
  position: relative;
}

