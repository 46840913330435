@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Inter:400,300,700,500");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
/* @import url("https://px.animaapp.com/62d6f4f81fd0e917fb849226.62d6f4f8af5c243f02ebd796.AP5ljFv.rcp.png"); */



.screen a {
  display: contents;
  text-decoration: none;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}
