.left-frame {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 14px;
  position: relative;
  width: 180px;
}

