.footer {
  align-self: stretch;
  background-color: var(--white);
  /* box-shadow: 0px 0px 10px 2px #0084ff26;  */
  height: 36px;
  min-width: 647px;
  position: relative;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .footer {
    width: 95%;
    min-width: 95%;
  }
}

.logo {
  height: 18px;
  /* left: 94px;
  position: relative;
  top: 9px; */
  width: 109px;
}

.created-by-noodle-group-llc-with {
  color: var(--fuscous-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 400;
  align-self: center;
  justify-self: center;
  /* height: 12px;
  left: 214px;
  letter-spacing: 0;
  position: relative;
  top: 11px; */
  /* margin: auto; */
}

