.button {
  align-items: center;
  background-color: var(--blue-ribbon);
  border-radius: 11px;
  display: flex;
  gap: 9px;
  height: min-content;
  justify-content: center;
  padding: 4px 12px 5px 20px;
  width: min-content;
}

.button-1 {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 34px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.frame-shape {
  height: 8px;
  min-width: 7px;
}

.button.button-2 .button-1 {
  min-width: 65px;
}

