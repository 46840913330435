.project {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 3px 2px #0084ff20;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: min-content;
  padding: 18px 30px 19px;
  position: relative;
  width: 649px;
}

@media only screen and (max-width: 834px) {
  .project {
    width: 95%;
  }
}

.project.sans-border {
  box-shadow: none;
}

.t-summary {
  color: var(--fuscous-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 700;
  letter-spacing: 0;
}

.frame-para-1 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: min-content;
}

.para-1 {
  color: var(--delta);
  flex: 1;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: -1px;
  text-align: justify;
}

.frame-button {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: min-content;
  justify-content: center;
  padding: 12px 0px 5px;
  position: relative;
}

