.varbar-frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: min-content;
  justify-content: center;
  padding: 0px 0px 10px;
}

.frame-varbar-bg {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--alto);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 76px;
  height: min-content;
  justify-content: center;
}

/* blue: '#004CFF',
red: '#E84855',
green: '#21CC7E',
yellow: '#FFE07E', */

.frame-varbar-gradient {
  align-self: stretch;
  /* background: linear-gradient(116deg, #E84855 0.00%, #FFE07E 65.00%, #21CC7E 100.00%); */
  background: linear-gradient(116deg, #004CFF 0.00%, #E84855 33.00%, #FFE07E 66.00%, #21CC7E 100.00%);
  background-size: 800% 100%;
  border-radius: 34px;
  height: 13px;
  min-width: 0px
}

