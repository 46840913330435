.right-bin {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  height: min-content;
  position: relative;
}

