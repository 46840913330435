.frame-breadcrumbs {
  align-items: flex-start;
  display: flex;
  gap: 3px;
  height: min-content;
  padding: 0px 11px 0px 0px;
  width: min-content;
}

.text-breadcrumbs {
  color: var(--fuscous-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 500;
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 59px;
}

.text-breadcrumbs-1 {
  color: var(--fuscous-gray);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 300;
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 132px;
  width: 100%;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

