.project-header {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 15px;
  height: min-content;
  padding: 10px 0px;
  position: relative;
}

.text-project-name-1 {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 164px;
}

.frame-status {
  align-items: center;
  background-color: var(--alto);
  border-radius: 100px;
  display: flex;
  gap: 10px;
  height: 36px;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  width: 36px;
}

.shape-status {
  background-color: var(--blue-ribbon);
  border-radius: 13px;
  height: 26px;
  min-width: 26px;
}

