.navbar {
  /* align-items: flex-start;
  align-self: stretch;
  display: flex; */
  gap: 2px;
  height: min-content;
}

.navbar-link-task {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 35px;
}

.navbar-link-task-comp {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 11px;
}

.navbar-link-task2 {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 62px;
}

.navbar-link-task-total {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 13px;
}

.navbar-link-due {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 40px;
}

.navbar-link-date {
  color: #ff0000;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: 300;
  letter-spacing: 0;
  margin-top: -1px;
}

