.task {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 3px 2px #0084ff20;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: min-content;
  justify-content: center;
  padding: 14px 20px;
  position: relative;
  width: 647px;
}

.line-1 {
  align-self: stretch;
  height: 1px;
  min-width: 607px;
}

.frame-task-body {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 10px;
  height: min-content;
  padding: 15px 33px 10px;
  position: relative;
}


