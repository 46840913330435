.completed-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 0px 1px;
}

.frame-static-2 {
  align-items: center;
  display: flex;
  gap: 4px;
  height: min-content;
  justify-content: center;
  width: min-content;
}

.calendar2-check-fill {
  height: 11px;
  min-width: 11px;
}

.completed-2 {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 54px;
  font-family: Inter;
  font-weight: 500;
  font-size: small;
}

.date-1 {
  letter-spacing: 0;
  margin-top: -1px;
  min-width: 57px;
  font-family: Inter;
  color: #bbb;
  font-size: small;
}

